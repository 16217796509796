// extracted by mini-css-extract-plugin
export var blockText = "Collectible-module--blockText--yQtxs";
export var body = "Collectible-module--body--CWa6x";
export var btn = "Collectible-module--btn--mn0mR";
export var btnM = "Collectible-module--btnM--REEel";
export var collectibles = "Collectible-module--collectibles--YSJR9";
export var container = "Collectible-module--container--el3FY";
export var img = "Collectible-module--img--fOEVC";
export var stick = "Collectible-module--stick--0SuF-";
export var subtitle = "Collectible-module--subtitle--sXlG+";
export var textBlock = "Collectible-module--textBlock---yOUO";
export var textItem = "Collectible-module--textItem--Kv-+8";
export var title = "Collectible-module--title--Izv9L";
export var top = "Collectible-module--top--aKju9";
export var topText = "Collectible-module--topText--mMt8A";