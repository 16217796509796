import React from 'react'
import * as styles from './NftCard.module.scss'

const nftCard = () => {
  return (
    <section className={styles.nftCard}>
      <div className={styles.container}>
        <div className={styles.title}>
          <span className={styles.nft}>NFT</span> CARD TYPES
        </div>
        <div className={styles.subtitle}>
          There are two types of NFTs in Reds Club Metaverse: membership cards and collectible
          cards. All cards are stored in blockchain and you will need to install and use proper
          wallet to receive and keep them.
        </div>
      </div>
    </section>
  )
}

export default nftCard
