import * as React from "react";
import { Helmet } from "react-helmet-async";
import Layout from "../component/Layout";
import Collectible from "../component/Collectible";
import NftCard from "../component/nftCard";
import PersonalCard from "../component/PersonalCard";

// markup
const Competitions = () => {
  return (
    <>
      <Helmet>
        <title> About NFT - Reds Club</title>
        <meta property='og:title' content='About NFT  - Reds Club' />
        <meta
          name='description'
          content='About NFT Reds Club.There are two types of NFTs in Reds Club Metaverse: membership cards and collectible cards. All cards are stored in blockchain and you will need to install and use proper wallet to receive and keep them '
        />
        <meta
          property='og:description'
          content='About NFT Reds Club.There are two types of NFTs in Reds Club Metaverse: membership cards and collectible cards. All cards are stored in blockchain and you will need to install and use proper wallet to receive and keep them'
        />
      </Helmet>
      <Layout>
        <main>
          <NftCard />
          <PersonalCard />
          <Collectible />
        </main>
      </Layout>
    </>
  );
};

export default Competitions;
