import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import useWindowDimensions from '../../lib/useDemenstion'
import Button from '../common/Button'
import * as styles from './Collectible.module.scss'

const Collectible = () => {
  const { width } = useWindowDimensions()
  return (
    <section className={styles.collectibles}>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.stick}></div>
          <div className={styles.topText}>2 card type</div>
        </div>
      </div>
      <div>
        <div className={styles.container}>
          <div className={styles.body}>
            <div className={styles.title}>Collectible card</div>
            <div className={styles.subtitle}>is a prize card</div>
            <div className={styles.blockText}>
              <div className={styles.textItem}>
                Collectible cards are NFT fan artworks - they are created by Liverpool fans
                community.
              </div>
              <div className={styles.textItem}>
                These cards can be received only by owners of the current season membership cards.
              </div>
              <div className={styles.textItem}>
                During the football season we will have a number of competitions for Reds Club’s
                members - all the participants will take part in distribution of collectible NFT
                cards, and the winners will receive rare prize NFTs.
              </div>
              <div className={styles.textItem}>
                It is not possible to buy collectible cards from the Club - these cards are prizes
                for active Reds Club members and competition winners only.
              </div>
            </div>
            <Link to='/about?show=3#2' className={styles.btn}>
              <Button>READ MORE ABOUT CARDS</Button>
            </Link>
          </div>
        </div>

        <div className={styles.img}>
          <StaticImage
            placeholder='blurred'
            width={564}
            src='../../images/personalCard/collertible_cards_2X.png'
            alt='photo'
          />
        </div>
        <Link to='/about?show=3#2' className={styles.btnM}>
          <Button justifyContent='center'>
            READ MORE ABOUT {width && width < 430 ? '' : 'COLLECTIBLE'} CARDS
          </Button>
        </Link>
      </div>
    </section>
  )
}

export default Collectible
