// extracted by mini-css-extract-plugin
export var blockText = "PersonalCard-module--blockText--6ND4P";
export var body = "PersonalCard-module--body--M89LP";
export var btn = "PersonalCard-module--btn--8JbE0";
export var btnM = "PersonalCard-module--btnM--oQC0c";
export var container = "PersonalCard-module--container--GWd8F";
export var content = "PersonalCard-module--content--PTb8F";
export var img = "PersonalCard-module--img--5HuL0";
export var maiBlock = "PersonalCard-module--maiBlock--EM1wi";
export var personalCard = "PersonalCard-module--personalCard--YZYur";
export var stick = "PersonalCard-module--stick--3PMbu";
export var subtitle = "PersonalCard-module--subtitle--NKXxS";
export var textBlock = "PersonalCard-module--textBlock--3q1Ig";
export var textItem = "PersonalCard-module--textItem--0CtKe";
export var title = "PersonalCard-module--title--RvtT1";
export var top = "PersonalCard-module--top--pMAb2";
export var topText = "PersonalCard-module--topText--a4KJB";