import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Button from '../common/Button'
import * as styles from './PersonalCard.module.scss'

const PersonalCard = () => {
  return (
    <div className={styles.personalCard}>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.stick}></div>
          <div className={styles.topText}>1 card type</div>
        </div>
      </div>
      <div className={styles.maiBlock}>
        <div className={styles.content}>
          <div className={styles.container}>
            <div className={styles.body}>
              <div className={styles.title}>membership card</div>
              <div className={styles.subtitle}>is a personal card</div>
              <div className={styles.blockText}>
                <div className={styles.textItem}>
                  Everyone who wants to enter the Reds Club and take part in its activities must get
                  a membership card.
                </div>
                <div className={styles.textItem}>
                  Membership card is an artwork NFT that represents a picture of a person, you can
                  use your photo or standard picture.
                </div>
                <div className={styles.textItem}>
                  You can mint one membership card each season - it gives you access to all Reds
                  Club’s activities during current football season.
                </div>
                <div className={styles.textItem}>Membership cards are non-transferable.</div>
              </div>
              <a target='_blank' href='https://forms.gle/DXxUsNJ3E5m74hYv8' className={styles.btn}>
                <Button>Apply for membership</Button>
              </a>
            </div>
          </div>
        </div>
        <div className={styles.img}>
          <StaticImage
            placeholder='blurred'
            width={564}
            src='../../images/personalCard/membership-card.png'
            alt='photo'
          />
        </div>
        <a target='_blank' href='https://forms.gle/DXxUsNJ3E5m74hYv8' className={styles.btnM}>
          <Button justifyContent='center'>Apply for membership</Button>
        </a>
      </div>
    </div>
  )
}

export default PersonalCard
